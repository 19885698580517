import React, { useState, useEffect } from "react";
import Nav from "./Nav";
import Header from "./Header";
import Footer from "./Footer";

const Gallery = ({ isDarkMode, toggleDarkMode }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Fetch images or load them from a local source
    const fetchImages = async () => {
      const imageList = [
        {
          id: 1,
          src: "/gallery/quote1.jpeg",
          title: "",
          description:
            "Be patient with yourself, nothing in nature bloom all year",
        },
        {
          id: 1,
          src: "/gallery/quote2.jpeg",
          title: "",
          description: "Be your own kind of beautiful",
        },
        {
          id: 1,
          src: "/gallery/quote3.jpeg",
          title: "",
          description: "You are my Nemo",
        },
      ];
      setImages(imageList);
    };

    fetchImages();
  }, []);

  return (
    <div
      className={`Gallery w-full min-h-screen mx-auto p-0 bg-orange-50 text-gray ${isDarkMode ? "dark" : ""} dark:bg-gray-800 dark:text-zinc-50 break-words leading-6 transition-colors duration-500`}
    >
      <Nav isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
      {/* <Header /> */}

      <div className="Header Container header-gallery-container">
        <div className="header-content">
          <div className="header-description text-2xl font-mplus font-medium">Well-being & Mental-health</div>
        </div>
      </div>

      <div className="gallery-description p-2">
        <h1 className="text-3xl font-bold text-center font-mono m-4">
          Gallery
        </h1>
        <p className="text-base italic text-center font-ubuntu px-5">
          Getting peace.
        </p>
      </div>
      <div className="grid p-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 m-10">
        {images.map((image) => (
          <div
            key={image.id}
            className="border p-4 rounded-md px-5 shadow-md dark:shadow-gray-600"
          >
            <img
              src={process.env.PUBLIC_URL + image.src}
              alt={image.title}
              className="w-full h-auto object-contain rounded-md mb-4"
            />
            <h2 className="text-lg font-semibold mb-2">{image.title}</h2>
            <p className="mb-2 italic">{image.description}</p>
          </div>
        ))}
      </div>
      <hr className="border-t border-gray-300 dark:border-gray-600 my-8 mx-auto w-4/5" />

      {/* <div className="youtube-playlist p-2 flex flex-col items-center">

        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/videoseries?list=PLJol4u7_9M9aIejqNzriKfUfdP6a8arIc&autoplay=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div> */}

      <footer class="relative bg-blueGray-700 pt-8 pb-6">
        <div class="flex flex-wrap items-center">
          <div class="w-full md:w-4/12 px-4 mx-auto text-center">
            <div class="text-sm text-blueGray-700 font-semibold py-1">
              Copyright © {new Date().getFullYear()} - Long Bui. All rights
              reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Gallery;
