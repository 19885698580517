import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import Header from "./Header";
import Nav from "./Nav";
import Footer from "./Footer";

const Home = () => {
  const [products, setProducts] = useState([]);
   const [isDarkMode, setIsDarkMode] = useState(false);
   const [selectedProduct, setSelectedProduct] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const context = require.context("../../public/posts/", false, /\.md$/);
        const files = context.keys();

        const productsData = await Promise.all(
          files.map(async (file) => {
            const contentResponse = await fetch(`/posts/${file}`);
            const content = await contentResponse.text();

            const regex = /^---\n(.*?)\n---\n(.*)$/s;
            const match = content.match(regex);

            if (match) {
              const frontmatter = match[1];
              const data = frontmatter.split("\n").reduce((result, line) => {
                const [key, value] = line.split(":").map((s) => s.trim());
                if (key && value) {
                  result[key] = value;
                }
                return result;
              }, {});


              if (data.public === "true") {
                const product = {
                  slug: data.slug || "",
                  title: data.title || "",
                  description: data.description || "",
                  price: data.price || "",
                  payment: data.payment || "",
                  paymentDescription: data.paymentDescription || "",
                  buy: 'https://'+data.buy || "",
                  content: match[2],
                };
                return product;
              }
            } else {
              console.error("Frontmatter not found in file:", file);
            }
            return null;
          })
        );

        setProducts(productsData.filter((product) => product !== null));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    document.documentElement.classList.toggle('dark');
  };

  const openModal = (product) => {
    setSelectedProduct(product);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (selectedProduct && !event.target.closest('.modal-content')) {
        closeModal();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [selectedProduct]);



  return (
    <div className={`App w-full min-h-screen mx-auto p-0 bg-orange-50 text-gray ${isDarkMode ? 'dark' : ''} dark:bg-gray-800 dark:text-zinc-50 break-words leading-6 transition-colors duration-500`}>
      <Nav
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
      />

      <Header />

      <div className="home-description p-2">
        <h1 className="text-3xl font-bold text-center font-mono m-4">
          What I've been creating as a Data Engineer
        </h1>
          <p className="text-base italic font-ubuntu  px-5">
            I've been spending hours and hours in front of computer
            every day. So, I've been continuously improving the workflows in order
            to boost the productivity. Most of the items are my gears / brain's / curated list of teching. If you like to know more about me, you can check out my blog, my Youtube channel, or my LinkedIn profile.
            <a> To know more About me
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="inline" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144m124-144H100"></path></svg>
            </a>
            <a className="inline-flex items-center gap-1 indent-1 text-orange-500" href="http://youtube.com/@longdatadevlog">Youtube Channel
            </a> ,
            <a className="inline-flex items-center gap-1 indent-1 text-orange-500" href="http://www.longdatadevlog.com">Blog
            </a> ,
            <a className="inline-flex items-center gap-1 indent-1 text-orange-500" href="https://www.linkedin.com/in/long-bui-van">LinkedIn
            </a> ,
            <a className="inline-flex items-center gap-1 indent-1 text-orange-500" href="https://www.de-book.longdatadevlog.com">Handbook
            </a>
          </p>

          {/*
                    <div class="Digital Products ">
                    <div class="text-base font-bold font-i mt-5 font-mono text-xl text-base-800">Section 1: Digital Products - Improve knowledge base</div>
                    <a className="inline-flex items-center gap-1 indent-1 text-orange-500" href="https://payhip.com/longdatadevlog">
                    <img class="border border-slate-300 dark:border-zinc-700 rounded-xl" alt="Digital Products banner" width="1440" height="720" src="Digital-Products.png" loading="lazy" decoding="async"></img>
                    </a>
                  </div>
          */}

      </div>
        <div className="grid p-2 grid-cols-1 md:grid-cols-2 gap-8 m-10">
                {products.map((product, index) => (
                  <div key={index} className="border p-4 rounded-md px-5 shadow-md dark:shadow-gray-600">
                    <h2 className="text-lg font-semibold mb-2">{product.title}</h2>
                    <p className="mb-2 italic">{product.description}</p>
                    <p className="mb-2 line-clamp-3 text-sm text-gray-600 dark:text-gray-300">
                      {product.content.split(' ').slice(0, 100).join(' ')}
                      {product.content.split(' ').length > 100 ? '...' : ''}
                    </p>
                    <br/>
                      <p className="mb-2">Price: {product.price}</p>
                        <p className="mb-2 italic">
                          Register by filling the
                          <a
                            href={product.buy}
                            className="link mt-4 ml-2 text-cyan-700"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Form
                          </a>
                        </p>
                    <button
                      onClick={() => openModal(product)}
                      className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    >
                      View Details
                    </button>
                  </div>
                ))}
              </div>

              {selectedProduct && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                  <div className="modal-content bg-white dark:bg-gray-800 p-8 rounded-lg max-w-4xl w-full max-h-[80vh] overflow-y-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-400 scrollbar-track-gray-200">
                    <h2 className="text-2xl font-bold mb-4">{selectedProduct.title}</h2>
                      <p className="mb-4 italic">{selectedProduct.description}</p>
                      <div className="mb-4 prose prose-sm dark:prose-invert">
                        <ReactMarkdown
                          components={{
                            h1: ({ node, ...props }) => <h1 className="text-2xl font-bold mt-4 mb-2" {...props} />,
                            h2: ({ node, ...props }) => <h2 className="text-xl font-semibold mt-3 mb-2" {...props} />,
                            h3: ({ node, ...props }) => <h3 className="text-lg font-medium mt-2 mb-1" {...props} />,
                            p: ({ node, ...props }) => <p className="mb-2" {...props} />,
                            ul: ({ node, ...props }) => <ul className="list-disc list-inside mb-2" {...props} />,
                            ol: ({ node, ...props }) => <ol className="list-decimal list-inside mb-2" {...props} />,
                            li: ({ node, ...props }) => <li className="mb-1" {...props} />,
                            a: ({ node, ...props }) => <a className="text-blue-500 hover:underline" {...props} />,
                            blockquote: ({ node, ...props }) => <blockquote className="border-l-4 border-gray-300 pl-4 italic my-2" {...props} />,
                            code: ({ node, inline, ...props }) =>
                              inline ? (
                                <code className="bg-gray-100 dark:bg-gray-700 rounded px-1" {...props} />
                              ) : (
                                <pre className="bg-gray-100 dark:bg-gray-700 rounded p-2 overflow-x-auto">
                                  <code {...props} />
                                </pre>
                              ),
                          }}
                        >
                          {selectedProduct.content}
                        </ReactMarkdown>
                      </div>
                        {/*
                        <a
                          href={selectedProduct.buy}
                          className="block mb-4 text-blue-500 hover:underline"
                          target="_blank"
                          rel="noopener noreferrer"
                        >

                      Buy with Paypal
                      </a>
                      */}
                        <a className="font-bold"> Payment: </a>
                        {selectedProduct.payment && (
                          <p className="mb-4 italic">
                            Bank: {selectedProduct.payment}
                            <br />
                            Description: {selectedProduct.paymentDescription}
                          </p>
                        )}
                          <h2 className="text-xl font-semibold mt-4 mb-2">Visit Other Store</h2>
                          <a
                            href="https://payhip.com/longdatadevlog"
                            className="block mb-4 text-blue-500 hover:underline"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Payhip/@longdatadevlog
                          </a>
                           <p className="mb-4 text-gray-600 dark:text-gray-400">
                              Note: This product provides online reading access without download, apology for disappointing you.
                            </p>
                            <p className="mb-4 text-gray-600 dark:text-gray-400">
                              I value your feedback! Please share your thoughts on your purchase by filling out this <a href="https://forms.gle/FH4tHqX12GKYpWCQA" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">feedback form</a>.
                            </p>
                    <button
                      onClick={closeModal}
                      className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}

      {/*
      <div class="text-base font-bold font-i font-mono text-xl text-base-800 p-2">Section 2: Affiliate Products - Improve productivity</div>
      */}
        {/*
              <div className="grid p-2 grid-cols-1 md:grid-cols-2 gap-8">
                {products.map((product, index) => (
                  <div key={index} className="border p-4 rounded-md px-5 shadow-md px-2">
                    <h2 className="text-lg font-semibold mb-2">{product.title}</h2>
                    <p className="mb-2 italic">{product.description}</p>
                    <br/>
                      <p className="mb-2 italic">
                        Register by clicking the link below:
                        <a
                          href={product.buy}
                          className="link mt-4 indent-4 text-cyan-700"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Referral link
                        </a>
                      </p>

                    <div className="mt-4 indent-4 rounded-xl">
                      <ReactMarkdown>{product.content}</ReactMarkdown>
                    </div>
                  </div>
                ))}
              </div>
        */}

      <Footer />

    </div>
  );
};

export default Home;
